<template>
  <div>
    <div>
      <div>
        <b-button
          variant="success"
          id="show-btn"
          @click="$bvModal.show('bv-modal-example')"
          >Thêm Phòng Họp</b-button
        >

        <b-modal id="bv-modal-example" hide-footer>
          <template #modal-title> Form Thêm Phòng Họp </template>
          <div class="d-block">
            <b-form>
              <b-form-group>
                <label for="roomName">Tên Phòng</label>
                <b-form-input
                  id="roomName"
                  type="text"
                  placeholder="Nhập tên phòng"
                  :state="validateState('roomName')"
                  v-model="$v.takeInfoFromInput.roomName.$model"
                  required
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  Không được bỏ trống
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form>
                <label for="seats">Sức Chứa Tối Đa</label>
                <b-form-input
                  id="seats"
                  type="number"
                  placeholder="Nhập số lượng người tối đa"
                  :state="validateState('seats')"
                  v-model="$v.takeInfoFromInput.seats.$model"
                  required
                >
                </b-form-input>
                <b-form-invalid-feedback>
                  Không được bỏ trống
                </b-form-invalid-feedback>
              </b-form>
            </b-form>
          </div>
          <div class="d-flex">
            <b-button
              class="mt-3 mr-5"
              variant="success"
              block
              v-on:click="addRoom"
              >Thêm</b-button
            >

            <b-button class="mt-3" variant="danger" block @click="hideModal"
              >Đóng</b-button
            >
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service.js";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data() {
    return {
      takeInfoFromInput: {
        roomName: "",
        seats: null,
      },
    };
  },
  validations: {
    takeInfoFromInput: {
      roomName: { required },
      seats: { required },
    },
  },
  props: {
    getListRoom: {
      type: Function,
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.takeInfoFromInput[name];
      return $dirty ? !$error : null;
    },
    addRoom() {
      this.$v.takeInfoFromInput.$touch();
      if (this.$v.takeInfoFromInput.$anyError) {
        return;
      }
      // perform logic to add user to database or make API call here
      ApiService.post("/room/createRoom", this.takeInfoFromInput)
        .then(() => {
          this.getListRoom();
          this.hideModal();
          successNotification("thêm phòng thành công");
        })
        .catch(() => {
          errorNotification("thêm thất bại");
        });
      // reset form
    },
    hideModal() {
      this.$bvModal.hide("bv-modal-example");
    },
  },
};
</script>
<style></style>

<template>
  <div>
    <b-card>
      <FormAddMeetingRoom :getListRoom="getListRoom" />
      <FormEditMeetingRoom
        v-bind:roomInfo="roomInfo"
        v-on:editRoom="editRoom"
      />
      <b-tabs pills card>
        <b-table striped hover :items="roomList" :fields="fields">
          <template v-slot:cell(deleted)="row">
            <div class="text-center" v-if="row.item.deleted === false">
              còn hoạt động
            </div>
            <div class="text-center" v-else>đã xóa</div>
          </template>

          <template v-slot:cell(seats)="row">
            <p class="text-center">
              {{ row.item.seats }}
            </p>
          </template>

          <template v-slot:cell(roomName)="row">
            <p class="text-center">
              {{ row.item.roomName }}
            </p>
          </template>

          <template v-slot:cell(id)="row">
            <p class="text-center">
              {{ row.item.id }}
            </p>
          </template>

          <template v-slot:cell(action)="row">
            <div class="d-flex align-items-center justify-content-center">
              <!-- btn sửa -->
              <div class="cursor_pointer mr-5">
                <i
                  class="fa fa-user-edit text-info"
                  v-b-modal:[`edit-room-${row.item.id}`]
                  v-on:click="handleEdit(row)"
                ></i>
              </div>

              <!-- btn khôi phục -->
              <div class="cursor_pointer mr-5" v-on:click="handleRestore(row)">
                <i class="fa fa-sync text-warning"></i>
              </div>

              <!--  btn xóa -->
              <div class="cursor_pointer" v-on:click="handleDeleted(row)">
                <i class="fa fa-trash text-danger"></i>
              </div>
            </div>
          </template>
        </b-table>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import FormAddMeetingRoom from "@/view/content/popupmodals/AddMeetingRoom.vue";
import FormEditMeetingRoom from "@/view/content/popupmodals/EditRoom.vue";
import ApiService from "@/core/services/api.service.js";
import {
  successNotification,
  errorNotification,
} from "@/core/helpers/notification";
export default {
  data() {
    return {
      roomList: [
        {
          id: "1",
          roomName: "Phòng tận tâm",
        },
      ],
      fields: [
        { key: "id", label: "ID", thStyle: { textAlign: "center" } },
        {
          key: "roomName",
          label: "Tên Phòng",
          thStyle: { textAlign: "center" },
        },
        {
          key: "seats",
          label: "Sức Chứa Tối Đa",
          thStyle: { textAlign: "center" },
        },
        {
          key: "deleted",
          label: "Trạng Thái",
          thStyle: { textAlign: "center" },
        },
        { key: "action", label: "Thao tác", thStyle: { textAlign: "center" } },
      ],
      roomInfo: null,
    };
  },
  methods: {
    handleDeleted(row) {
      const id = row.item.id;
      ApiService.delete(`/room/deletedRoom/${id}`)
        .then(() => {
          this.getListRoom();
          successNotification("khôi phục thành công");
        })
        .catch(() => {
          errorNotification("khôi phục thất bại");
        });
    },
    handleEdit(row) {
      this.roomInfo = { ...row.item };
    },
    handleRestore(row) {
      const id = row.item.id;
      ApiService.put(`/room/restoreRoom/${id}`)
        .then(() => {
          this.getListRoom();
          successNotification("khôi phục thành công");
        })
        .catch(() => {
          errorNotification("khôi phục thất bại");
        });
    },

    editRoom(db) {
      const { roomName, id, seats } = db;
      let updateRoom = {
        roomName,
        seats,
      };
      ApiService.put(`/room/updateRoom/${id}`, updateRoom)
        .then(() => {
          this.getListRoom();
          successNotification("cập nhật thành công");
        })
        .catch(() => {
          errorNotification("cập nhật thất bại");
        });
    },
    getListRoom() {
      ApiService.get("/room/getAllOfRoom").then((db) => {
        const { data } = db.data;
        this.roomList = data;
      });
    },
  },
  components: {
    FormAddMeetingRoom,
    FormEditMeetingRoom,
  },
  mounted() {
    this.getListRoom();
    // Lấy dữ liệu từ database và gán vào biến users
    // Sử dụng axios hoặc fetch để gửi request lấy dữ liệu
    // Ví dụ: axios.get('/api/users').then(response => this.users = response.data);
  },
};
</script>
<style>
.cursor_pointer {
  cursor: pointer;
}
</style>

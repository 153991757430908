  <template>
  <div>
    <b-modal
      v-if="roomInfo !== null"
      :id="`edit-room-${roomInfo?.id}`"
      hide-footer
    >
      <template #modal-title> Form Thêm Phòng Họp </template>
      <div class="d-block">
        <b-form v-on:submit.prevent="submit">
          <b-form-group>
            <label for="roomName">Tên Phòng</label>
            <b-form-input
              id="roomName"
              type="text"
              placeholder="Nhập tên phòng"
              v-model.trim="$v.takeValuesFromInput.roomName.$model"
              :state="validateState('roomName')"
              required
            >
            </b-form-input>
            <b-form-invalid-feedback>
              Yêu Cầu Nhập Tên Phòng
            </b-form-invalid-feedback>
          </b-form-group>

          <b-form-group>
            <b-form>
              <label for="seats">Sức Chứa Tối Đa</label>
              <b-form-input
                id="seats"
                type="number"
                placeholder="Nhập số lượng người tối đa"
                :state="validateState('seats')"
                v-model="$v.takeValuesFromInput.seats.$model"
                required
              >
              </b-form-input>
              <b-form-invalid-feedback>
                Không được bỏ trống
              </b-form-invalid-feedback>
            </b-form>
            <b-form-invalid-feedback>
              Yêu Cầu Nhập Tên Phòng
            </b-form-invalid-feedback>
          </b-form-group>
        </b-form>
      </div>
      <div class="d-flex">
        <b-button class="mt-3" variant="info" block v-on:click="editRoom"
          >Cập Nhật</b-button
        >
      </div>
    </b-modal>
  </div>
</template>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  mixins: [validationMixin],
  data() {
    return {
      takeValuesFromInput: {
        roomName: "",
        seats: null,
      },
    };
  },
  props: {
    roomInfo: {
      type: Object,
    },
  },
  validations: {
    takeValuesFromInput: {
      roomName: { required },
      seats: { required },
    },
  },
  watch: {
    roomInfo(newValue) {
      this.takeValuesFromInput.roomName = newValue.roomName;
      this.takeValuesFromInput.seats = newValue.seats;
    },
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.takeValuesFromInput[name];
      return $dirty ? !$error : null;
    },
    hideModal() {
      this.$bvModal.hide(`edit-room-${this.roomInfo?.id}`);
    },
    editRoom() {
      this.$v.takeValuesFromInput.$touch();
      if (this.$v.takeValuesFromInput.$anyError) {
        return;
      }
      const { roomName, seats } = this.$v.takeValuesFromInput.$model;
      const data = {
        roomName,
        id: this.roomInfo.id,
        seats: seats,
      };
      console.log(data);
      this.$emit("editRoom", data);
      this.hideModal();
    },
  },
};
</script>
<style></style>
